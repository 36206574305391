/** 
 * @Desc: components--找回密码
 */
<template>
  <div class="pwd-card">
    <p class="dialog-title">找回密码</p>
    <el-form
      v-if="step === 1 || step === 2"
      label-position="right"
      :model="infoForm"
      :rules="ruleForm"
      ref="ruleForm"
      label-width="100px"
      class="self-el-form"
    >
      <template v-if="step === 1">
        <el-form-item label="手机号：" prop="phone">
          <el-input
            id="pwdInput"
            v-model.trim="infoForm.phone"
            placeholder="请输入手机号（平台登录账号）"
            ref="accountPhone"
            :maxlength="11"
            v-enterNumber
          ></el-input>
        </el-form-item>
        <el-form-item label="" prop="slidingVerification" class="sliding-verification">
          <el-button
            v-if="!phoneR1 && !infoForm.slidingVerification"
            size="small"
            @click="_slidingVerifiyBtnClick()"
          >点击进行安全验证</el-button>
          <el-button
            v-else-if="infoForm.slidingVerification"
            size="small"
            class="is-success-verify"
          >验证成功</el-button>
          <el-popover
            v-show="phoneR1 && !infoForm.slidingVerification"
            v-model="popoverVisible"
            placement="top"
            title=""
            trigger="click"
            content=""
            popper-class="verify-card-popover"
            @after-leave="_popoverHide"
          >
            <div class="top-box">
              <span class="card-title float-left">完成拼图验证</span>
              <i class="iconfont iconclose float-right cursor" @click.stop="_closeVerify"></i>
              <span class="change-it float-right" @click.stop="_refreshVerify">
                <img class="change-img" src="../../assets/new_ui/change.png" alt="">
                <i class="iconfont iconrefresh"></i>换一张
              </span>
            </div>
            <div id="mpanel4"></div>
            <el-button
              class="btn-reference"
              size="small"
              slot="reference"
              @click="_slidingVerifiyBtnClick()"
            >
              点击进行安全验证
              <span class="btn-mask" v-if="popoverVisible" @click.stop></span>
            </el-button>
          </el-popover>
        </el-form-item>
        <el-form-item label="验证码：" prop="verificationCode" class="verification-code">
          <el-input v-model.trim="infoForm.verificationCode" placeholder="请输入验证码" :maxlength="4"></el-input>
          <span
            class="get-code"
            :class="{
              'get-code': true,
              'get-code-status1': !infoForm.slidingVerification || countDown,
              'get-code-status2': infoForm.slidingVerification && !countDown,
            }"
            @click="infoForm.slidingVerification && !countDown && _sedCode()"
          >{{infoForm.slidingVerification && countDown ? countDown + 's 后重新获取' : '获取验证码'}}</span>
        </el-form-item>
      </template>
      <template v-if="step === 2">
        <el-form-item label="密码：" prop="password">
          <el-input
            class="mvr-el-input"
            :type="inputType"
            v-model.trim="infoForm.password"
            :maxlength="14"
            auto-complete="false"
            placeholder="密码长度6-14位，字母区分大小写"
          ></el-input>
        </el-form-item>
        <el-form-item label="确认密码：" prop="checkPassword">
          <el-input
            class="mvr-el-input"
            :type="inputType"
            v-model.trim="infoForm.checkPassword"
            :maxlength="14"
            auto-complete="false"
            placeholder="请再次输入密码"
          ></el-input>
        </el-form-item>
        <el-form-item class="form-empty">
          <el-input></el-input>
        </el-form-item>
      </template>
    </el-form>
    <div v-if="step === 1 || step === 2" class="bottom-btns">
      <el-button
        type="primary"
        size="small"
        :loading="okLoading"
        @click="!okLoading && _save()"
      >{{step === 1 ? "下一步" : "确定"}}</el-button>
    </div>
    <div v-if="step === 3" class="step-3">
      <img class="img-step-successful" src="../../assets/new_ui/step-successful.png" alt="">
      <div class="bottom-btns">
        <el-button
          type="primary"
          size="small"
          :loading="okLoading"
          @click="!okLoading && _save()"
        >立即登录（{{loginNow}}s）</el-button>
      </div>
    </div>
  </div>
</template>
<script>
import bus from "utils/bus";
import verify from "utils/verify";
import store from "store";
import { mapState } from "vuex";
import {
  reqAccountRepeat,
  reqSendCode,
  reqRegisterCode,
  reqRegisterOrForgotPwd
} from "api/personal-center";
import { localStorageUtil, validataPhone, debounce } from "utils/util";
import verifyImage1 from "assets/verify-images/1.png";
import verifyImage2 from "assets/verify-images/2.png";
import verifyImage3 from "assets/verify-images/3.png";
import verifyImage4 from "assets/verify-images/4.png";
import verifyImage5 from "assets/verify-images/5.png";

export default {
  props: {
    visible: {
      type: Boolean
    }
  },
  data() {
    const phoneR = async (rule, val, callback) => {
      if (this.step === 2) {
        return callback();
      }
      if (!val) {
        return callback(new Error("请输入手机号"));
      } else if (!validataPhone(val)) {
        return callback(new Error("请输入正确的账号"));
      } else if (this.okLoading && this.isVerfiyRepeat) {
        return callback(new Error("请输入正确的账号"));
      } else if (this.needVerfiyRepeat) {
        this.needVerfiyRepeat = false;
        let _r = await this._accountRepeat();
        if (!_r) {
          this.isVerfiyRepeat = true;
          return callback(new Error("请输入正确的账号"));
        }
        this.isVerfiyRepeat = false;
        return callback();
      } else if (this.resCode === 12008) {
        this.resCode = null;
        return callback(new Error("请输入正确的账号"));
      }
      callback();
    };
    const phoneOldR = (rule, val, callback) => {
      if (this.step === 2) {
        return callback();
      }
      if (this.infoForm.phoneOld !== val && this.infoForm.slidingVerification) {
        this.infoForm.slidingVerification = false;
      }
      this.infoForm.phoneOld = val;
    };
    const slidingVerificationR = (rule, val, callback) => {
      if (this.step === 2) {
        return callback();
      }
      if (this.okLoading && !val) {
        return callback(new Error("请进行安全验证"));
      }
      callback();
    };
    const verificationCodeR = (rule, val, callback) => {
      if (this.step === 2) {
        return callback();
      }
      if (this.okLoading && !val) {
        return callback(new Error("请输入短信验证码"));
      }
      callback();
    };
    const passwordR = (rule, val, callback) => {
      if (this.step === 1) {
        return callback();
      }
      let reg = /^[a-zA-Z0-9]{6,14}$/g;
      if (this.okLoading && !val) {
        return callback(new Error("请输入密码"));
      } else if (!val) {
        return callback();
      } else if (val.length > 14 || val.length < 6) {
        return callback(new Error("密码长度6-14位，字母区分大小写"));
      } else if (!reg.test(val)) {
        return callback(new Error("密码仅支持英文和数字"));
      }
      callback();
    };
    const checkPasswordR = (rule, val, callback) => {
      if (this.step === 1) {
        return callback();
      }
      if (this.okLoading && !val && !this.infoForm.password) {
        callback(new Error("请输入密码"));
      } else if (this.okLoading && !val && this.infoForm.password) {
        callback(new Error("请再次输入密码"));
      } else if (val && val !== this.infoForm.password) {
        callback(new Error("两次输入密码不一致"));
      } else {
        callback();
      }
    };
    return {
      infoForm: {
        phone: "",
        phoneOld: "",
        slidingVerification: false,
        verificationCode: "",
        password: "",
        checkPassword: ""
      },
      ruleForm: {
        phone: [
          { trigger: "blur", validator: phoneR },
          { trigger: "change", validator: phoneOldR }
        ],
        slidingVerification: [
          { trigger: "change", validator: slidingVerificationR }
        ],
        verificationCode: [{ trigger: "blur", validator: verificationCodeR }],
        password: [{ trigger: "blur", validator: passwordR }],
        checkPassword: [{ trigger: "blur", validator: checkPasswordR }]
      },
      countDown: 0,
      inputType: "text",
      okLoading: false,
      popoverVisible: false,
      verifyObj: null,
      loginNow: 5,
      needVerfiyRepeat: false,
      isVerfiyRepeat: false,
      resCode: null,
      step: 1
    };
  },
  computed: {
    ...mapState(["isLogin", "accountInfo"]),
    phoneR1() {
      if (!this.infoForm.phone || !validataPhone(this.infoForm.phone)) {
        return false;
      }
      return true;
    }
  },
  watch: {
    visible(val, oldVal) {
      if (val === false && oldVal === true && this.$refs.ruleForm) {
        this.$refs.ruleForm.resetFields();
        this.step = 1;
      }
    }
  },
  methods: {
    /**
     * 点击按钮进行安全验证
     * **/
    _slidingVerifiyBtnClick(type) {
      if (!this.phoneR1) {
        this.$refs.ruleForm.validateField("phone", errMsg => {
          this.$refs.accountPhone.focus();
        });
      } else {
        this.$refs.ruleForm.validateField("slidingVerification", errMsg => {});
        this._verify();
      }
    },
    /**
     * 校验账号是否已被注册
     * **/
    _accountRepeat() {
      let obj = {
        phone: this.infoForm.phone,
        type: 2
      };
      return reqAccountRepeat(obj)
        .then(res => {
          if (res.code === 0) {
            return true;
          } else if (res.code === 12008) {
            return false;
          } else {
            console.log("账号校验失败", res.data);
            return false;
          }
        })
        .catch(err => {
          console.log("账号校验失败", err);
          return false;
        });
    },

    /**
     * 关闭滑动验证
     * */
    _closeVerify() {
      this.popoverVisible = false;
    },

    /**
     * 隐藏popover完毕后触发
     * */
    _popoverHide() {
      $("#mpanel4").html("");
    },

    /**
     * 刷新滑动验证
     * */
    _refreshVerify() {
      if (this.verifyObj) {
        this.verifyObj.refresh();
      }
    },

    /**
     * 初始化滑动校验
     * */
    _verify() {
      let _this = this;
      this.$nextTick(() => {
        $("#mpanel4").slideVerify({
          type: 2, //类型
          vOffset: 3, //误差量，根据需求自行调整
          vSpace: 3, //间隔
          imgUrl: "",
          imgName: [
            verifyImage1,
            verifyImage2,
            verifyImage3,
            verifyImage4,
            verifyImage5
          ],
          imgSize: {
            width: "246px",
            height: "110px"
          },
          blockSize: {
            width: "30px",
            height: "30px"
          },
          barSize: {
            width: "246px",
            height: "38px"
          },
          ready: function(val) {
            _this.verifyObj = val;
          },
          success: function() {
            _this._closeVerify();
            _this.needVerfiyRepeat = true;
            _this.$refs.ruleForm.validateField("phone", errMsg => {
              if (!errMsg) {
                _this.infoForm.slidingVerification = true;
              }
            });
            // console.log("验证成功，添加你自己的代码！");
            //......后续操作
          },
          error: function() {
            //		        	alert('验证失败！');
          }
        });
      });
    },

    /**
     * 发送code验证码
     * */
    _sedCode: debounce(function() {
      this.countDown = 60;
      let obj = {
        phone: this.infoForm.phone,
        type: 2
      };
      return reqSendCode(obj)
        .then(res => {
          this.resCode = res.code;
          if (res.code === 0 || res.code === 200) {
            // this.countDown = 10;
            const _timer = setInterval(() => {
              if (this.countDown) {
                this.countDown--;
              } else {
                clearInterval(_timer);
              }
            }, 1000);
            this.$once("hook:beforeDestory", () => {
              clearInterval(_timer);
            });
            this.$message({
              type: "success",
              message: "验证码已发送",
              duration: 2000
            });
          } else if (res.code === 12008) {
            this.countDown = 0;
            this.$refs.ruleForm.validateField("phone", errMsg => {
              this.$refs.accountPhone.focus();
            });
          } else if (res.code === 14011) {
            this.countDown = 0;
            this.$message({
              type: "error",
              message: "发送次数已超限，请稍后再试",
              duration: 2000
            });
          } else if (res.code === 14012) {
            this.countDown = 0;
            this.$message({
              type: "error",
              message: "验证码发送失败，请重新发送",
              duration: 2000
            });
          } else {
            this.countDown = 0;
            this.$message({
              type: "error",
              message: "验证码发送失败",
              duration: 2000
            });
          }
        })
        .catch(err => {
          console.log("验证码发送失败", err);
        });
    }),

    /**
     * 校验短信验证码是否可用
     * **/
    _codeRegister() {
      let obj = {
        phone: this.infoForm.phone,
        code: this.infoForm.verificationCode,
        type: 2
      };
      return reqRegisterCode(obj)
        .then(res => {
          this.okLoading = false;
          if (res.code === 0 || res.code === 200) {
            this.step = 2;
          } else if (res.code === 14021) {
            this.$message({
              type: "error",
              message: "验证码无效或已过期，请重新发送验证码",
              duration: 2000
            });
          } else {
            console.log("验证码校验失败", res.data);
          }
        })
        .catch(err => {
          this.okLoading = false;
          console.log("验证码校验失败", err);
        });
    },

    /**
     * 保存
     * **/
    _save: debounce(function() {
      if (this.step !== 3) {
        this.okLoading = true;
        this.$refs.ruleForm.validate(valid => {
          if (valid) {
            if (this.step === 1) {
              this._codeRegister();
            } else if (this.step === 2) {
              this._resetPwd();
            }
          } else {
            this.okLoading = false;
          }
        });
      } else {
        this.$emit("handlerComponent", "login");
      }
    }),

    /**
     * 找回密码
     * **/
    _resetPwd() {
      let obj = {
        type: 2,
        phone: this.infoForm.phone,
        code: this.infoForm.verificationCode,
        password: this.infoForm.password
      };
      return reqRegisterOrForgotPwd(obj)
        .then(res => {
          this.okLoading = false;
          if (res.code === 0) {
            this.step = 3;
            this.loginNow = 5;
            const _timer = setInterval(() => {
              if (this.loginNow) {
                this.loginNow--;
              } else {
                this.$emit("handlerComponent", "login");
                clearInterval(_timer);
              }
            }, 1000);
            this.$once("hook:beforeDestory", () => {
              clearInterval(_timer);
            });
          } else {
            this.$message({
              type: "error",
              message: "失败，请重试",
              duration: 2000
            });
          }
        })
        .catch(err => {
          this.okLoading = false;
          console.log("失败", err);
        });
    }
  },
  mounted() {
    setTimeout(() => {
      this.inputType = "password";
    }, 500);
  }
};
</script>
<style lang="less">
.pwd-card {
  height: 350px;
  .el-form {
    padding-bottom: 0px;
    .sliding-verification {
      .el-form-item__content .el-button {
        width: 280px;
        // width: 2.8rem;
        height: 36px;
        line-height: 34px;
        font-size: 14px;
      }
      .is-success-verify {
        background: #ade787;
        cursor: default !important;
      }
    }
    .el-input {
      // width: 2.8rem;
      // height: 0.36rem;

      // .el-input__inner {
      //   height: 100%;
      // }
    }
    .verification-code {
      .el-form-item__content {
        .el-input {
          width: 150px;
          // width: 1.24rem;
          // height: 0.36rem;

          // .el-input__inner {
          //   height: 100%;
          // }
        }
        .get-code {
          display: inline-block;
          text-align: center;
          width: 120px;
          height: 36px;
          // width: 1.24rem;
          // height: 0.36rem;
          // line-height: 0.36rem;
          border-radius: 10px;
          margin-left: 12px;
          &.get-code-status1 {
            color: #595959;
            background: #e5e5e3;
            cursor: not-allowed;
          }
          &.get-code-status2 {
            color: #cb7e09;
            background: #ffecbe;
            cursor: pointer;
            &:hover {
              opacity: 0.8;
            }
          }
        }
      }
    }
    .form-empty {
      height: 0;
      margin-bottom: 0 !important;
      overflow: hidden;
    }
  }
  .bottom-btns {
    margin-bottom: 20px;
    margin-top: 50px;
    .el-button {
      width: 280px;
      // width: 2.8rem;
      margin-left: 100px;
    }
  }
  .step-3 {
    text-align: center;
    .bottom-btns {
      margin-top: 40px;
      margin-bottom: 20px;
      .el-button {
        margin-left: 0;
      }
    }
  }
}
</style>
