/** 
 * @Desc: components--登录 注册 找回密码
 */
<template>
  <el-dialog
    :visible.sync="dialogVisible"
    width="566px"
    :append-to-body="true"
    :close-on-click-modal="false"
    :before-close="_dialogClose"
    custom-class="self-el-dialog"
  >
    <LoginComponent
      v-if="componentType === 'login'"
      :visible="visible"
      @handlerComponent="_componentChange"
      @handlerClose="_componentClose"
      @handlerRecord="_playRecord"
    />
    <RegisterComponent
      v-else-if="componentType === 'register'"
      :visible="visible"
      @handlerComponent="_componentChange"
      @handlerClose="_componentClose"
      @handlerRecord="_playRecord"
    />
    <PwdComponent
      v-else-if="componentType === 'pwd'"
      :visible="visible"
      @handlerComponent="_componentChange"
      @handlerClose="_componentClose"
    />
    <ResetPwdComponent
      v-else-if="componentType === 'reset-pwd'"
      :visible="visible"
      @handlerComponent="_componentChange"
      @handlerClose="_componentClose"
    />
    <WelcomeComponent
      v-else-if="componentType === 'welcome'"
      :visible="visible"
      @handlerComponent="_componentChange"
      @handlerClose="_componentClose"
    />
  </el-dialog>
</template>
<script>
import bus from "utils/bus";
import { mapState } from "vuex";
import { localStorageUtil } from "utils/util";
import LoginComponent from "./login";
import RegisterComponent from "./register";
import PwdComponent from "./pwd";
import ResetPwdComponent from "./reset-pwd";
import WelcomeComponent from "./welcome";
export default {
  components: {
    LoginComponent,
    RegisterComponent,
    PwdComponent,
    ResetPwdComponent,
    WelcomeComponent
  },
  props: {
    visible: {
      type: Boolean
    },
    component: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      dialogVisible: false,
      componentType: ""
    };
  },
  watch: {
    visible(val, oldVal) {
      this.dialogVisible = val;
      if (val) {
        this.componentType = this.component;
      }
      if (
        val &&
        (this.componentType === "login" ||
          this.componentType === "register" ||
          this.componentType === "pwd")
      ) {
        this.$nextTick(() => {
          $(`#${this.componentType}Input`).focus();
        });
      }
    }
  },
  computed: {
    ...mapState(["accountInfo"])
  },
  methods: {
    _dialogClose(type) {
      this.$emit("handleClick", type ? type : false);
      if (this.componentType === "welcome") {
        this._playRecord();
        bus.$emit("Reload");
      }
      this.dialogVisible = false;
      this.componentType = "";
    },
    _componentChange(val) {
      this.componentType = val;
      if (
        this.componentType === "login" ||
        this.componentType === "register" ||
        this.componentType === "pwd"
      ) {
        setTimeout(() => {
          $(`#${this.componentType}Input`).focus();
        }, 300);
      }
    },
    _componentClose(val) {
      this._dialogClose();
    },

    /**
     * 登录后如果在播放页 要定位到登录前的那个资源
     * */
    _playRecord() {
      let { name } = this.$route;
      let playReord = localStorageUtil.getItem("playReord");
      if (name !== "ResourcePlay" || !this.accountInfo || !playReord) {
        return;
      }
      let phone = this.accountInfo.phone ? this.accountInfo.phone + "_" : "";
      if (!phone) {
        return;
      }
      let packingHistory = localStorageUtil.getItem(phone + "packingHistory");
      if (!packingHistory) {
        packingHistory = {};
      }
      packingHistory = Object.assign({}, packingHistory, playReord);
      localStorageUtil.setItem(phone + "packingHistory", packingHistory);
    }
  },
  mounted() {}
};
</script>
<style lang="less">
</style>